<template>
    <div class="row m-0">
        <div class="col-12 p-0">
            <table class="table table-bordered text-center">
                <thead>
                    <tr>
                        <th scope="col">{{ `[1] ${monthOrYear.text} 목표 설정` }}</th>
                        <th scope="col">{{ `[2] ${resultData.predict ? "예상 " : ""}소비량` }}</th>
                        <th scope="col">{{ `[3] ${resultData.predict ? "예상 " : ""}절감량` }}</th>
                        <th scope="col" class="highlightTableItem">
                            {{ `[4] ${resultData.predict ? "예상 " : ""}절감비율(%)` }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="tableItem align-middle">
                            {{ `${formatNumWithComma(goalAccValue.value)} ${goalAccValue.unit}` }}
                        </td>
                        <td class="tableItem align-middle">
                            {{ `${formatNumWithComma(targetUsageValue.value)} ${targetUsageValue.unit}` }}
                        </td>
                        <td class="tableItem align-middle">
                            {{ `${formatNumWithComma(savingValue.value)} ${savingValue.unit}` }}
                        </td>
                        <td class="highlightTableItem tableItem align-middle">
                            {{ savingPercent != null && isFinite(savingPercent) ? `${savingPercent}%` : "-" }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- 연간 테이블 (월간일 경우 안보여야함) -->
        <div v-if="!monthOrYear.isMonth" class="col-12 p-0">
            <table class="table table-bordered text-center">
                <thead>
                    <tr class="highlightTableItem">
                        <th scope="col">{{ "구분" }}</th>
                        <th v-for="(reg, index) in resultData.values.regDt" :key="'reg-' + index" scope="col">
                            {{ reg }}
                        </th>
                        <th scope="col">{{ "합계" }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="highlightTableItem">{{ `목표 설정(${goalUnit})` }}</td>
                        <td
                            v-for="(goal, index) in goalValue.value"
                            :class="calTableColor(index)"
                            :key="'goal-' + index"
                        >
                            {{ `${formatNumWithComma(goal)} ${goalUnit}` }}<br />
                        </td>
                        <td class="customDanger">{{ `${formatNumWithComma(goalValue.sum)} ${goalUnit}` }}</td>
                    </tr>
                    <tr>
                        <td class="highlightTableItem">{{ `소비량(${mainUnit})` }}</td>
                        <td
                            v-for="(use, index) in targetValue.value"
                            :class="calTableColor(index)"
                            :key="'use-' + index"
                        >
                            {{ `${formatNumWithComma(use)} ${mainUnit}` }}
                        </td>
                        <td class="customDanger">{{ `${formatNumWithComma(targetValue.sum)} ${mainUnit}` }}</td>
                    </tr>
                    <tr>
                        <td class="highlightTableItem">{{ `절감량(${mainUnit})` }}</td>
                        <td
                            v-for="(save, index) in savingOfYear.value"
                            :key="'saving-' + index"
                            :class="calTableColor(index)"
                        >
                            {{ `${formatNumWithComma(save)} ${mainUnit}` }}
                        </td>
                        <td class="customDanger">{{ `${formatNumWithComma(savingOfYear.sum)} ${mainUnit}` }}</td>
                    </tr>
                    <tr>
                        <td class="highlightTableItem">{{ "절감률(%)" }}</td>
                        <td
                            v-for="(per, index) in savingOfYearPercent.value"
                            :class="calTableColor(index)"
                            :key="index + 10000"
                        >
                            {{ `${formatNumWithComma(filterfalsy(per))} %` }}
                        </td>
                        <td class="customDanger">
                            {{ `${formatNumWithComma(filterfalsy(savingOfYearPercent.sum))} %` }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-12 p-0">
            <p class="mb-1">
                {{ `[1] ${monthOrYear.text} 목표 설정 : 사전 입력된 월간 목표 설정량` }}
            </p>
            <p class="mb-1">
                {{ `[2] 예상 소비량 : ${monthOrYear.text} 소비량 데이터를 회귀분석과 이동평균 보정을 사용하여 추정치` }}
            </p>
            <p class="mb-1">
                {{ "[3] 예상 절감량 : [1] 설정 목표량 – [2] 예상 소비량" }}
            </p>
            <p class="mb-3">
                {{ "[4] 예상 절감비율 : [3] 예상 절감량 / [1] 설정 목표량 * 100" }}
            </p>
            <p class="mb-0">
                {{
                    "※ 예상 절감량 분석은 추이 분석을 위해 매월 7일 이후 조회 가능하고 월 후반기에 갈 수록 정확도가 높아집니다."
                }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: ["detailInfo", "resultData"],
    components: {},
    data() {
        return {
            yearTableData: null,
            currentMonth: new Date().getMonth(),

            emptyDataMonth: [],
        };
    },
    computed: {
        goalHeader() {
            return this.resultData.headers.find((item) => item.roleType == "Goal") ?? {};
        },
        mainHeader() {
            return this.resultData.headers.find((header) => header.roleType == "Main") ?? {};
        },
        goalUnit() {
            const goalUnit = this.$store.state.units.find((unit) => unit.value == this.goalHeader.unitSmallCode);
            return this.goalHeader.dispUnit ? this.goalHeader.dispUnit : goalUnit.text;
        },
        mainUnit() {
            const mainUnit = this.$store.state.units.find((unit) => unit.value == this.mainHeader.unitSmallCode);
            return this.mainHeader.dispUnit ? this.mainHeader.dispUnit : mainUnit.text;
        },
        monthOrYear() {
            let date;
            if (this.mainHeader.timeDsvn == "DAY") {
                date = {
                    text: "월간",
                    isMonth: true,
                };
            } else
                date = {
                    text: "연간",
                    isMonth: false,
                };
            return date;
        },
        // 목표 설정
        goalAccValue() {
            let goal;
            if (this.goalHeader && this.resultData) {
                const item = this.resultData?.accumulate[this.goalHeader.ptIdx];
                goal = {
                    value: item[item.length - 1],
                    unit: this.goalUnit,
                };
            } else goal = { value: "-", unit: "-" };
            return goal;
        },
        // (예상) 소비량
        targetUsageValue() {
            let target;
            if (!this.isEmpty(this.mainHeader)) {
                if (!this.isEmpty(this.resultData.predict)) {
                    const predict = this.resultData.predict[this.mainHeader.ptIdx];
                    target = {
                        value: predict[predict.length - 1],
                        unit: this.mainUnit,
                    };
                } else {
                    const value = this.resultData.accumulate[this.mainHeader.ptIdx];
                    target = {
                        value: value[value.length - 1],
                        unit: this.mainUnit,
                    };
                }
            }
            return target;
        },
        // (예상) 절감량
        savingValue() {
            let save = {
                value: (this.goalAccValue.value - this.targetUsageValue.value).toFixed(1),
                // symbol: "",
                unit: this.mainUnit,
            };
            // if (save.value > 0) save.symbol = "+";
            // else save.symbol = "";
            return save;
        },
        // (예상) 절감 비율
        savingPercent() {
            console.log("calc savingpercent", this.savingValue.value, this.goalAccValue.value);
            let percent = ((this.savingValue.value / this.goalAccValue.value) * 100).toFixed(1);
            return percent;
        },
        // timeDsvn == "YEAR"
        // 연간 목표량
        goalValue() {
            const sum = this.resultData.values[this.goalHeader.ptIdx].reduce((acc, cur) => acc + cur, 0);
            const value = {
                value: this.resultData.values[this.goalHeader.ptIdx] ?? [],
                sum: sum ?? null,
            };
            return value;
        },
        // 연간 소비량
        targetValue() {
            const sum = this.resultData.values[this.mainHeader.ptIdx].reduce((acc, cur) => acc + cur, 0);
            const value = {
                value: this.resultData.values[this.mainHeader.ptIdx] ?? [],
                sum: sum ? sum.toFixed(1) : null,
            };
            return value;
        },
        // 연간 절감량(목표량 - 소비량)
        savingOfYear() {
            const goalValue = this.resultData.values[this.goalHeader.ptIdx];
            const mainValue = this.resultData.values[this.mainHeader.ptIdx];
            let value = {
                value: [],
                sum: null,
            };
            this.resultData.values.regDt.forEach((item, index) => {
                let saving = (goalValue[index] - mainValue[index]).toFixed(1);
                value.value.push(saving);
            });
            const sum = value.value.reduce((acc, cur) => Number(acc) + Number(cur), 0);
            value.sum = sum ? sum.toFixed(1) : null;
            return value;
        },
        // 연간 절감률(절감량 / 목표량)
        savingOfYearPercent() {
            let value = {
                value: [],
                sum: null,
            };
            this.resultData.values.regDt.forEach((item, index) => {
                let save = this.savingOfYear.value[index] ? this.savingOfYear.value[index] : 0;
                let goal = this.goalValue.value[index] !== null ? this.goalValue.value[index] : 0;
                let per = save / goal ? ((save / goal) * 100).toFixed(1) : 0;
                value.value.push(per);
            });
            const sum = value.value.reduce((acc, cur) => Number(acc) + Number(cur), 0);
            value.sum = sum ? sum.toFixed(1) : null;
            return value;
        },
        sumDisPercentValue() {
            // <목표분석> 합산값에 대한 절감률
            return ((this.sumDisValue / this.sumTargetValue) * 100).toFixed(0);
        },
    },
    watch: {
        resultData: {
            deep: true,
            handler() {
                this.notCalMonth();
            },
        },
    },
    created() {},
    mounted() {
        this.notCalMonth();
    },
    methods: {
        filterfalsy(value) {
            if (value != null && isFinite(value)) {
                return value;
            } else {
                return "-";
            }
        },
        calTableColor(index) {
            if (this.emptyDataMonth.includes(index)) {
                return "empty";
            } else if (index < this.currentMonth) {
                return "exist";
            } else {
                return "predict";
            }
        },
        notCalMonth() {
            this.emptyDataMonth = this.resultData.values[this.detailInfo.ptIdx]
                .map((item, index) => (item === null ? index : null)) // null이면 인덱스를 반환, 아니면 null 반환
                .filter((index) => index !== null); // null인 경우를 제거
        },

        formatNumWithComma(value) {
            let formatValue;

            if (!isNaN(value)) {
                formatValue = typeof value == "number" ? value._comma() : Number(value)._comma();
            } else {
                formatValue = value;
            }
            return formatValue;
        },
    },
};
</script>
<style scoped>
.empty {
    background-color: white;
}
.exist {
    background-color: #fff3e0;
}
.predict {
    background-color: #e8f5e9;
}
.customDanger {
    background-color: #e8eaf6;
    font-weight: bold;
}
.highlightTableItem {
    background-color: #eceff1;
}
.tableItem {
    height: 50px;
    font-weight: bold;
    font-size: 13px;
}
</style>
