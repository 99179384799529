<template>
    <div class="hourHeatMapContainer">
        <div class="hourHeadMapBox">
            <div class="title pl-2 pt-2 d-flex justify-content-center">시간대별 패턴 분석</div>
            <div ref="heatmap" class="hourHeatMap"></div>
        </div>

        <div class="weekAvg">
            <div class="title pl-2 pt-2 d-flex justify-content-center">
                주차별 사용량 순위
            </div>
            <bar-chart-next-heat-map
                :detailInfo="this.detailInfo"
                :resultData="this.resultData.weeklyAverage"
            ></bar-chart-next-heat-map>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import BarChartNextHeatMap from "./BarChartNextHeatMap.vue";
export default {
    props: ["resultData", "detailInfo"],
    components: { BarChartNextHeatMap },
    data() {
        return {
            chart: null,
        };
    },
    watch: {
        resultData: {
            deep: true,
            handler(newVal) {
                if (newVal.hourlyPattern.value.length !== 0) {
                    this.initHeatMap();
                }
            },
        },
    },
    mounted() {
        this.initHeatMap();
        window.addEventListener("resize", this.resizeChart);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resizeChart);
        if (this.chart) {
            this.chart.dispose();
        }
    },
    methods: {
        getMinMax(data) {
            let min = Number.POSITIVE_INFINITY;
            let max = Number.NEGATIVE_INFINITY;

            data.forEach((item) => {
                const value = item[2]; // Heatmap value
                if (value < min) min = value;
                if (value > max) max = value;
            });

            return { min, max };
        },
        initHeatMap() {
            this.chart = echarts.init(this.$refs.heatmap);

            const ySeries = this.resultData.hourlyPattern.ySeries || [];
            const xSeries = this.resultData.hourlyPattern.xSeris || [];
            const unit = this.resultData.headers[0].unitSmallCode;
            const { min, max } = this.getMinMax(this.resultData.hourlyPattern.value);

            const option = {
                tooltip: {
                    position: "top",
                    formatter: function(params) {
                        return `<div style="font-size: 12px; width: 170px;display: flex; justify-content: space-between;">
                                    <div>
                                    ${params.marker}
                                    <span> ${ySeries[params.value[1]]}</span>
                                    <span> ${xSeries[params.value[0]]}시</span></span>
                                    </div>
                                    <span style="font-weight: bold;">${params.value[2]}&nbsp;${unit}</span>
                                </div> `;
                    },
                },
                visualMap: {
                    min: min, // Dynamic min value
                    max: max, // Dynamic max value
                    calculable: true,
                    orient: "horizontal",
                    left: "center",
                    bottom: "5%",
                },
                xAxis: {
                    type: "category",
                    data: xSeries,
                    splitArea: {
                        show: true,
                    },
                },
                yAxis: {
                    type: "category",
                    data: ySeries,
                    splitArea: {
                        show: true,
                    },
                    axisLabel: {
                        inside: false,
                        formatter: (hour) => `${hour}`,
                    },
                },
                grid: {
                    // left: "6%",
                    right: "3%",
                    width: "90%",
                    height: "70%",
                    bottom: "30%",
                },
                series: [
                    {
                        name: "Usage Pattern",
                        type: "heatmap",
                        data: this.resultData.hourlyPattern.value,
                        label: {
                            show: true,
                            formatter: (params) => params.value[2].toFixed(1),
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };

            this.chart.setOption(option);
        },
        resizeChart() {
            if (this.chart) {
                this.chart.resize();
            }
        },
    },
};
</script>

<style scoped>
.hourHeatMapContainer {
    width: 100%;
    height: 100%;
    display: flex;
}
.hourHeadMapBox {
    display: flex; /* 명시적으로 flexbox 설정 */
    flex-direction: column;
    width: 70%;
    height: 100%;
    box-shadow: 4px 0px 12px 0px rgba(0, 0, 0, 0.1); /* 확산 반경(0px) 추가 */
}
.hourHeatMap {
    width: 100%;
    height: 100%;
    padding-top: 20px;
}
.weekAvg {
    width: 30%;
    height: 100%;
}
.title {
    font-weight: bold;
}
</style>
