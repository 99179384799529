<template>
    <div style="width: 100%; height: 100%;">
        <div ref="rltnScatterChart" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import ecStat from "echarts-stat";

export default {
    props: ["chartResize", "resultData", "detailInfo", "corrData", "unit"],
    data() {
        return {
            chart: null,
        };
    },
    computed: {
        baseDataInfo() {
            const item = this.resultData.headers.find((header) => header.ptIdx === String(this.detailInfo.ptIdx));
            return item;
        },
    },
    watch: {
        chartResize() {
            if (this.chart) {
                this.chart.resize();
            }
        },
        resultData: {
            handler() {
                this.renderChart();
            },
            deep: true,
        },
        corrData: {
            handler() {
                this.renderChart();
            },
            deep: true,
        },
    },
    mounted() {
        this.renderChart();
        window.addEventListener("resize", this.resizeChart);
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
        window.removeEventListener("resize", this.resizeChart);
    },
    methods: {
        renderChart() {
            if (!this.corrData || !this.resultData || !this.corrData.correlation) {
                console.warn("Invalid data. Cannot render chart.");
                return;
            }

            // Initialize chart
            this.chart = echarts.init(this.$refs.rltnScatterChart);

            // Calculate regression line
            const regression = ecStat.regression("linear", this.corrData.correlation);

            // Format regression points
            regression.points = regression.points.map((point) => [
                parseFloat(point[0].toFixed(3)),
                parseFloat(point[1].toFixed(3)),
            ]);

            // Define chart options
            const option = {
                grid: {
                    top: "24%",
                },
                legend: {
                    bottom: 5,
                    data: ["Scatter", "Regression Line"],
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: { type: "cross" },
                    formatter: (params) => {
                        if (Array.isArray(params)) {
                            const xValue = params[0]?.value[0];
                            const yValue = params[0]?.value[1];

                            return `
                                ${this.corrData.ptName}:  
                                <span style="font-weight: bold;">${xValue.toFixed(1)}${
                                this.corrData.unit === "celsius" ? "°C" : this.corrData.unit
                            }</span><br/>
                                ${this.baseDataInfo.ptName}:  
                                <span style="font-weight: bold;">${yValue?.toFixed(1) || 0} ${this.unit}</span>`;
                        }
                        return "";
                    },
                },
                xAxis: {
                    name: this.corrData.ptName,
                    nameLocation: "middle",
                    nameGap: 25,
                    splitLine: {
                        lineStyle: {
                            type: "dashed",
                        },
                    },
                },
                yAxis: {
                    name: this.baseDataInfo.ptName,
                    splitLine: {
                        lineStyle: {
                            type: "dashed",
                        },
                    },

                    nameTextStyle: {
                        padding: [0, 0, 0, 120],
                    },
                },
                series: [
                    {
                        name: "Scatter",
                        type: "scatter",
                        data: this.corrData.correlation,
                    },
                    {
                        name: "Regression Line",
                        type: "line",
                        data: regression.points,
                        showSymbol: false,
                        markPoint: {
                            label: {
                                formatter: () => `${regression.expression}`,
                                position: "left",

                                fontSize: 15,
                            },
                            data: [{ coord: regression.points[regression.points.length - 1] }],
                        },
                    },
                ],
            };

            this.chart.setOption(option);
            this.$nextTick(() => {
                if (this.chart) {
                    this.chart.resize();
                }
            });
        },
    },
    resizeChart() {
        if (this.chart) {
            this.chart.resize();
        }
    },
};
</script>
