<template>
    <div style="width: 100%;">
        <div style="width: 100%; display: flex; justify-content: center;">
            <h5>{{ "분석 개요" }}</h5>
        </div>
        <table class="table table-bordered mb-0">
            <tbody class="text-center">
                <tr>
                    <th class="tableStyle" scope="row">{{ "분석명" }}</th>
                    <td class=" bg-white">{{ detailInfo.modelName ?? "-" }}</td>
                </tr>
                <tr>
                    <th class="tableStyle" scope="row">{{ "분석 일시" }}</th>
                    <td class=" bg-white">{{ modDate }}</td>
                </tr>
                <tr>
                    <th class="tableStyle" scope="row">{{ "분석 방법" }}</th>
                    <td class=" bg-white">{{ analysisMethod }}</td>
                </tr>
                <tr>
                    <th class="tableStyle" scope="row">{{ "데이터 유형" }}</th>
                    <td class=" bg-white">{{ dataType }}</td>
                </tr>
                <tr>
                    <th class="tableStyle" scope="row">{{ "분석 기간" }}</th>
                    <td class=" bg-white">
                        {{ `${detailInfo.fromDate ?? "-"} ~ ${detailInfo.toDate ?? "-"}` }}
                    </td>
                </tr>
                <tr>
                    <th class="tableStyle" scope="row">{{ "비교 방법" }}</th>
                    <td class=" bg-white">{{ compareType }}</td>
                </tr>
                <tr>
                    <th class="tableStyle" scope="row">{{ "비교 기간" }}</th>
                    <td class=" bg-white">
                        {{ `${detailInfo.fromDateComp ?? "-"} ~ ${detailInfo.toDateComp ?? "-"}` }}
                    </td>
                </tr>
            </tbody>
        </table>
        <table class="table table-bordered mb-0">
            <tbody>
                <tr>
                    <td class="tableStyle" style="font-weight: bold;">{{ "분석 대상" }}</td>
                </tr>
                <tr>
                    <td class=" bg-white">
                        <span class="pointBadge badge" @click="showPointDetail(detailInfo.ptIdx, $event)">
                            {{ detailInfo.ptIdx ?? "-" }}
                        </span>
                        {{ `${targetPtName} (${targetPtAddr})` }}
                    </td>
                </tr>
            </tbody>
        </table>
        <table class="table table-bordered mb-0">
            <tbody>
                <tr>
                    <td class="tableStyle" style="font-weight: bold;">{{ "구성 데이터" }}</td>
                </tr>
                <tr>
                    <td class=" bg-white" v-if="!isEmpty(detailInfo.variables)">
                        <p
                            v-for="(pt, index) in detailInfo.variables"
                            :key="pt.ptIdx"
                            :class="index == detailInfo.variables.length - 1 ? 'mb-0' : ''"
                        >
                            <span class="pointBadge badge" @click="showPointDetail(pt.ptIdx, $event)">
                                {{ pt.ptIdx ?? "-" }}
                            </span>

                            {{
                                ` ${pointList.find((point) => point.ptIdx == pt.ptIdx).ptName} (${
                                    pointList.find((point) => point.ptIdx == pt.ptIdx).ptAddr
                                })`
                            }}
                        </p>
                    </td>
                    <td class=" bg-white" v-else>
                        <p class="mb-0">{{ "구성 데이터 정보가 없습니다." }}</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <table class="table table-bordered">
            <tbody>
                <tr>
                    <td class="tableStyle" style="font-weight: bold;">{{ "분석 설정에 대한 설명" }}</td>
                </tr>
                <tr>
                    <td class=" bg-white">{{ detailInfo.description ?? "-" }}</td>
                </tr>
            </tbody>
        </table>
        <div style="width: 100%; margin-bottom: 10px;">
            <div style="width: 100%; display: flex; justify-content: center; margin-bottom: 10px;">
                <h5 style="margin: 0px 10px 0px 0px !important;">{{ "주요 이벤트" }}</h5>
                <div
                    class="search-btn"
                    @click="() => $refs['evnet-list-modal'].show()"
                    v-b-tooltip.hover
                    :title="$t('주요 이벤트 상세')"
                >
                    <span>{{ "…" }}</span>
                </div>
            </div>
            <div v-if="!isEmpty(calendarList)" class="list-ctn">
                <div v-for="(calendar, index) in calendarList" :key="index">
                    <p
                        v-if="index < 5"
                        :class="index == calendarList.length - 1 ? 'mb-0' : ''"
                        style="display: flex; align-items: center;"
                    >
                        <span class="badge mb-0" style="font-size: 12px; margin-right: 5px;">{{ calendar.date }}</span>
                        <span class="mb-0">{{ calendar.description }}</span>
                        <!-- {{ `${calendar.date} ${calendar.description}` }} -->
                    </p>
                </div>
            </div>
            <div v-else class="list-ctn">
                <p class="mb-0">{{ "주요 이벤트 정보가 없습니다." }}</p>
            </div>
        </div>
        <div style="width: 100%;">
            <div style="width: 100%; display: flex; justify-content: center; margin-bottom: 10px;">
                <h5 style="margin: 0px 10px 0px 0px !important;">{{ "분석 기록" }}</h5>
                <div class="search-btn" @click="showHistoryDetail" v-b-tooltip.hover :title="$t('분석 기록 상세')">
                    <span>{{ "…" }}</span>
                </div>
            </div>
            <div v-if="!isEmpty(commentList)" class="list-ctn">
                <div v-for="(comment, index) in commentList" :key="index">
                    <p
                        v-if="index < 5"
                        :class="index == commentList.length - 1 ? 'mb-0' : ''"
                        style="display: flex; align-items: center;"
                    >
                        <!-- {{ formatCommentInfo(comment) }} -->
                        <span class="badge mb-0" style="font-size: 12px; margin-right: 5px;">
                            {{ formatCommentInfo(comment).regDate }}
                        </span>
                        <span class="mb-0">{{ formatCommentInfo(comment).text }}</span>
                    </p>
                </div>
            </div>
            <div v-else class="list-ctn">
                <p class="mb-0">{{ "분석 기록 정보가 없습니다." }}</p>
            </div>
        </div>
        <b-sidebar
            v-model="isSideBarShow"
            ref="ptInfoDetail"
            id="ptInfoDetail"
            bg-variant="light"
            backdrop-variant="secondary"
            @hidden="closePointDetail"
            width="33vw"
            no-header
            shadow
            right
            backdrop
        >
            <div v-if="isPointDetail" style="height: 100%">
                <PointInfoDetail
                    ref="point-info-detail"
                    :title="''"
                    :ptIdx="ptIdx"
                    :noWatch="true"
                    @closed="closePointDetail"
                />
            </div>
        </b-sidebar>
        <b-modal ref="evnet-list-modal" size="lg" centered @hidden="() => $refs['evnet-list-modal'].hide()">
            <template #modal-header>
                <h5 class="m-0">{{ "주요 이벤트 목록" }}</h5>
            </template>
            <EventMgmtModalPanel :detailInfo="detailInfo" :calendarList="calendarList" @refresh="refreshCalenderList" />
            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button
                        class="btn btn-secondary"
                        type="button"
                        style="flex: 1"
                        @click="() => $refs['evnet-list-modal'].hide()"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import PointInfoDetail from "@src/views/new/point-management/point-mgmt/PointDetail.vue";
import EventMgmtModalPanel from "./EventMgmtModalPanel.vue";

export default {
    props: ["detailInfo", "calendarList"],
    components: {
        PointInfoDetail,
        EventMgmtModalPanel,
    },
    data() {
        return {
            isSideBarShow: false,
            ptIdx: null,
            isPointDetail: false,
        };
    },
    computed: {
        ...mapState({
            commonCodes: (state) => state.commonCodes,
        }),
        ...mapState({
            pointList: (state) => state.pointList,
        }),
        analysisMethod() {
            let match;
            if (!this.isEmpty(this.detailInfo.analysisMethod)) {
                match = this.commonCodes.analysisMethod.find((item) => item.value === this.detailInfo.analysisMethod);
            }
            return !this.isEmpty(match) ? match.text : "-";
        },
        dataType() {
            if (this.detailInfo) {
                const foundItem = this.commonCodes.timeDsvn.find((item) => item.value === this.detailInfo.timeDsvn);
                return foundItem ? foundItem.text : "-";
            } else {
                return "-";
            }
        },
        modDate() {
            let date;
            if (!this.isEmpty(this.detailInfo.modDate))
                date = moment(this.detailInfo.modDate).format("YYYY-MM-DD HH:mm:ss");
            else date = "-";
            return date;
        },
        compareType() {
            let type;
            if (!this.isEmpty(this.detailInfo.compareType))
                type = this.commonCodes.compareType.find((item) => item.value === this.detailInfo.compareType);
            return !this.isEmpty(type) ? type.text : "-";
        },
        targetPtName() {
            let name;
            if (!this.isEmpty(this.detailInfo.ptIdx))
                name = this.pointList.find((item) => item.ptIdx == this.detailInfo.ptIdx);
            return !this.isEmpty(name) ? name.ptName : "-";
        },
        targetPtAddr() {
            let address;
            if (!this.isEmpty(this.detailInfo.ptIdx))
                address = this.pointList.find((item) => item.ptIdx == this.detailInfo.ptIdx);
            return !this.isEmpty(address) ? address.ptAddr : "-";
        },
        // formatCommentRegDate() {
        //     let commentRegDate;
        //     if (!this.isEmpty(this.detailInfo.comments))
        //         commentRegDate = this.detailInfo.comments.map((item) =>
        //             moment(item.regDate).format("YYYY-MM-DD HH:mm:ss")
        //         );
        //     return !this.isEmpty(commentRegDate) ? commentRegDate : [];
        // },
        commentList() {
            return this.detailInfo.comments;
        },
    },
    watch: {
        // detailInfo: {
        //     deep: true,
        //     handler(newValue) {
        //         if (this.commonCodes && this.commonCodes.analysisMethod) {
        //             console.log("detailInfo 변경됨:", newValue.analysisMethod);
        //             this.localAnalysisWay(newValue.analysisMethod);
        //         }
        //     },
        // },
    },
    created() {},
    mounted() {},
    methods: {
        async showPointDetail(ptIdx, event) {
            if (ptIdx) {
                this.ptIdx = ptIdx;
                this.isSideBarShow = true;
                this.isPointDetail = true;
                await event.stopPropagation();
            } else return;
        },
        closePointDetail() {
            this.$refs.ptInfoDetail.hide();
            this.isSideBarShow = false;
            this.isPointDetail = false;
        },
        formatCommentInfo(comment) {
            // let regDate;
            // let text;
            let formatComment = {};
            if (comment) {
                // regDate = comment.regDate ? moment(comment.regDate).format("YYYY-MM-DD HH:mm:ss") : "-";
                // text = comment.comment ? comment.comment : "-";
                formatComment.regDate = comment.regDate ? moment(comment.regDate).format("YYYY-MM-DD") : "-";
                formatComment.text = comment.comment ? comment.comment : "-";
            }
            return formatComment;
        },
        showHistoryDetail() {
            this.$emit("detail");
        },
        calendarListRefresh() {
            this.$emit("getEnergyCalendarList");
        },
        refreshCalenderList(fromDate, toDate) {
            this.$emit("getEnergyCalendarList", fromDate, toDate);
        },
    },
};
</script>

<style scoped>
.pointBadge {
    cursor: pointer;
}
.tableStyle {
    background-color: #eeeeee;
}
.analysis-info {
    width: 100%;
}

.analysis-info > tbody > tr > th {
    padding: 8px;
    background-color: #e0e0e0;
}

.analysis-info > tbody > tr > td {
    padding: 8px;
    background-color: #eeeeee;
}

.search-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 20px;
    border: 1px solid #ececec;
    background-color: #d6d6d6;
    border-radius: 5px;
    transition: background-color 0.5s;
    cursor: pointer;
}

.search-btn:hover {
    color: #eceff1;
    background-color: #455a64;
}

.list-ctn {
    width: 100%;
    min-height: 120px;
    max-height: 175px;
    overflow-y: hidden;
    border: 1px solid #ececec;
    border-radius: 5px;
    padding: 10px;
}
</style>
