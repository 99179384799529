<template>
    <div v-if="eventDetail" class="row m-0">
        <div style="display: flex; width: 100%; margin: 0px 0px 10px 0px !important;">
            <div class="col-6">
                <label for="title">{{ "제목" }}</label>
                <input :value="eventDetail.title ?? ''" class="form-control" id="title" type="text" disabled />
            </div>
            <div class="col-6">
                <label for="category">{{ "카테고리" }}</label>
                <input :value="eventDetail.category ?? ''" class="form-control" id="category" type="text" disabled />
            </div>
        </div>
        <div style="display: flex; width: 100%; margin: 0px 0px 10px 0px !important;">
            <div class="col-6">
                <label for="date">{{ "등록일자" }}</label>
                <input :value="eventDetail.date ?? ''" class="form-control" id="date" type="text" disabled />
            </div>
            <div class="col-6">
                <label for="regWorker">{{ "등록자" }}</label>
                <input :value="eventDetail.regWorker ?? ''" class="form-control" id="regWorker" type="text" disabled />
            </div>
        </div>
        <div style="display: flex; width: 100%;">
            <div class="col-12">
                <label for="description">{{ "설명" }}</label>
                <textarea
                    :value="eventDetail.description ?? ''"
                    class="form-control"
                    id="description"
                    type="text"
                    row="3"
                    style="resize: none;"
                    disabled
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["eventDetail"],
    components: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>
