<template>
    <div class="dailyHeatMapContainer">
        <div class="dailyHeatMapBox pt-2 ">
            <div class="title pl-2 d-flex justify-content-center">
                일별 패턴 분석
            </div>

            <div ref="dailyHeatMap" class="dailyHeatMapChart"></div>
        </div>

        <div ref="weekDay" class="weekDay pt-2">
            <div class="title pl-2 d-flex justify-content-center">
                요일별 사용량 순위
            </div>
            <bar-chart-next-heat-map
                :detailInfo="this.detailInfo"
                :resultData="this.resultData.weekDayAverage"
            ></bar-chart-next-heat-map>
        </div>
        <div ref="weekly" class="week pt-2">
            <div class="title pl-2 d-flex justify-content-center">
                주차별 사용량 순위
            </div>
            <bar-chart-next-heat-map
                :detailInfo="this.detailInfo"
                :resultData="this.resultData.weeklyAverage"
            ></bar-chart-next-heat-map>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import BarChartNextHeatMap from "./BarChartNextHeatMap.vue";

export default {
    props: ["resultData", "detailInfo"],
    data() {
        return {
            chart: null,
        };
    },
    components: {
        BarChartNextHeatMap,
    },
    watch: {
        resultData: {
            deep: true,
            handler() {
                if (this.resultData.dailyPattern.length !== 0) {
                    this.renderChart();
                } else {
                    return;
                }
            },
        },
    },
    mounted() {
        this.renderChart();
        window.addEventListener("resize", this.resizeChart);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resizeChart);
        if (this.chart) {
            this.chart.dispose();
        }
    },
    methods: {
        getMinValue() {
            return this.resultData.dailyPattern.value.reduce((minVal, item) => {
                return minVal > item[2] ? item[2] : minVal;
            }, Number.POSITIVE_INFINITY);
        },
        getMaxValue() {
            return this.resultData.dailyPattern.value.reduce((maxVal, item) => {
                return maxVal < item[2] ? item[2] : maxVal;
            }, Number.NEGATIVE_INFINITY);
        },
        renderChart() {
            this.chart = echarts.init(this.$refs.dailyHeatMap);

            const xAxis = this.resultData.dailyPattern.xSeries;
            const yAxis = this.resultData.dailyPattern.ySeries;
            const unit = this.resultData.headers[0].unitSmallCode;
            const option = {
                tooltip: {
                    position: "top",
                    formatter: function(params) {
                        return `<div style="font-size: 12px;  width: 160px;display: flex; justify-content: space-between;">
                                    <div>
                                    ${params.marker}
                                    <span> ${yAxis[params.value[1]]}</span>
                                    <span> ${xAxis[params.value[0]]}</span>
                                     </div>
                                     <div>
                                    <span style="font-weight: bold;">${params.value[2]}&nbsp;${unit}</span>
                                    </div>
                                </div> `;
                    },
                },
                visualMap: [
                    {
                        show: true,
                        min: this.getMinValue(),
                        max: this.getMaxValue(),
                        calculable: true,
                        orient: "horizontal",
                        left: "center",
                        bottom: "5%",
                    },
                ],
                grid: {
                    left: "1%",
                    width: "98%",
                    height: "80%",
                    top: "0%",
                    bottom: "15%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    data: xAxis,

                    boundaryGap: true,
                    splitArea: {
                        show: true,
                    },
                },
                yAxis: {
                    type: "category",
                    data: yAxis,

                    boundaryGap: true,
                    splitArea: {
                        show: true,
                    },
                },
                series: [
                    {
                        type: "heatmap",
                        data: this.resultData.dailyPattern.value,

                        label: {
                            show: true,
                            formatter: function(params) {
                                return `${params.data[2]}`;
                            },
                            fontSize: 10,
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };

            this.chart.setOption(option);
            this.resizeChart();
        },
        resizeChart() {
            if (this.chart) {
                this.chart.resize();
            }
        },
    },
};
</script>

<style scoped>
.title {
    font-weight: bold;
}
.dailyHeatMapBox {
    padding-right: 15px;
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding-top: 10px;
    margin: 4rem 0px 5rem 0px; */
    box-shadow: 4px 0px 12px rgba(0, 0, 0, 0.1);
}
.dailyHeatMapContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.dailyHeatMapChart {
    width: 100%;
    height: 100%;
}
.weekDay {
    width: 30%;
    height: 100%;
    box-shadow: 4px 0px 12px rgba(0, 0, 0, 0.1);
}
.week {
    width: 25%;
    height: 100%;
}
</style>
