<template>
    <div>
        <date-picker
            ref="datePicker"
            v-model="internalValue"
            :type="calendarTypeFordatePicker"
            :range="rangeBoolean"
            :valueType="datePickerFormat"
            lang="ko"
            :placeholder="placeholder"
            @input="updateValue"
            :disabled="isDatePickerDisabled"
            :format="datePickerFormat"
            :minute-step="minuteStep"
        ></date-picker>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(isoWeek);
export default {
    components: {
        DatePicker,
    },
    props: [
        "value",
        "calendarType",
        "placeholder",
        "baseDayTime",
        "compareType",
        "start",
        "end",
        // "disabledCalendar",
        "detailInfo",
    ],
    data() {
        return {
            internalValue: this.value,
            calendarTypeFordatePicker: null,
            datePickerFormat: "",
            minuteStep: null,
            rangeBoolean: false,
            localCompareType: this.compareType,
        };
    },
    computed: {
        isDatePickerDisabled() {
            if (
                !this.calendarType
                //  || this.disabledCalendar
            )
                return true;

            if (this.end && this.localCompareType !== "User") return true;

            return false;
        },
    },
    watch: {
        calendarType(newVal) {
            if (newVal) {
                // this.internalValue = null;

                if (this.detailInfo && this.detailInfo.analysisMethod) {
                    this.rangeBoolean =
                        ["MONTH", "DAY", "YEAR"].includes(newVal) && this.detailInfo.analysisMethod !== "Goal";
                    this.$emit("range", this.rangeBoolean);
                } else {
                    this.rangeBoolean = false; // 기본값 설정
                    this.$emit("range", this.rangeBoolean);
                }
                this.transformCalendarType(newVal);
                this.timeFormat(newVal);
            }
        },
        localCompareType(newVal) {
            if (this.end && (newVal === null || newVal === undefined || newVal !== "User")) {
                this.$emit("input", null);
            }
        },
        compareType(newVal) {
            this.localCompareType = newVal;
        },
        value(newValue) {
            this.internalValue = newValue;
        },
    },
    created() {
        if (this.calendarType) {
            this.transformCalendarType(this.calendarType);
            this.timeFormat(this.calendarType);
        }
    },
    methods: {
        timeFormat(calendarType) {
            if (calendarType === "YEAR") {
                this.datePickerFormat = "YYYY";
            } else if (calendarType === "MONTH") {
                this.datePickerFormat = "YYYY-MM";
            } else {
                this.datePickerFormat = "YYYY-MM-DD";
                this.minuteStep = null;
            }
        },
        transformCalendarType(calendarType) {
            if (calendarType === "MONTH") {
                this.calendarTypeFordatePicker = "month";
            } else if (calendarType === "YEAR") {
                this.calendarTypeFordatePicker = "year";
            } else {
                this.calendarTypeFordatePicker = "date";
            }
        },
        updateValue(val) {
            // 배열로 된 날짜 범위일 경우
            if (Array.isArray(val) && val.length === 2) {
                const start = dayjs(val[0]);
                const end = dayjs(val[1]);

                const maxRange = this.getMaxRange();
                const difference = end.diff(start, maxRange.unit);

                if (difference > maxRange.limit) {
                    setTimeout(async () => {
                        await this.alertNoti(`${maxRange.message} 범위를 초과하였습니다. 최대 범위로 자동 조정됩니다.`);
                        this.internalValue = this.getTrimmedRange(start, maxRange.limit, maxRange.unit);
                        this.$emit("input", this.internalValue);
                    }, 100);
                } else {
                    this.internalValue = val;
                    this.$emit("input", this.internalValue);
                }
            } else {
                // 단일 날짜일 경우
                this.internalValue = val;

                this.$emit("input", this.internalValue);
            }
        },
        getMaxRange() {
            if (this.calendarType === "YEAR") {
                return { limit: 100, unit: "year", message: "100년" };
            } else if (this.calendarType === "MONTH") {
                return { limit: 36, unit: "month", message: "36개월" };
            } else if (this.calendarType === "DAY") {
                return { limit: 365, unit: "day", message: "365일" };
            }
            return { limit: 0, unit: "", message: "" };
        },
        getTrimmedRange(start, limit, unit) {
            const baseStart = this.baseDayTime && this.baseDayTime[0] ? dayjs(this.baseDayTime[0]) : start;
            const trimmedEnd = baseStart.add(limit, unit);
            return [baseStart.format("YYYY-MM-DD"), trimmedEnd.format("YYYY-MM-DD")];
        },
    },
};
</script>
