<template>
    <div class="markdownContainer">
        <markdown-it-vue :content="resultFormula"></markdown-it-vue>
    </div>
</template>

<script>
import MarkdownItVue from "markdown-it-vue";
import "markdown-it-vue/dist/markdown-it-vue.css";

export default {
    props: ["formula"],
    components: {
        MarkdownItVue,
    },
    data() {
        return {
            resultFormula: "",
        };
    },
    watch: {
        formula: {
            immediate: true,
            handler() {
                this.updateResultFormula();
            },
        },
    },
    methods: {
        formatEquation(equation) {
            let formattedEquation = equation
                .replace(/\*/g, " \\times ")
                .replace(/\//g, " \\div ")
                .replace(/\+/g, " + ")
                .replace(/-/g, " - ");

            formattedEquation = formattedEquation.replace(/\bX(\d+)\b/g, "\\mathbf{X_$1}");

            return `$$\\mathbf{\\normalsize Y = ${formattedEquation} \\\\ }$$`;
        },

        updateResultFormula() {
            this.resultFormula = this.formatEquation(this.formula || "");
        },
    },
};
</script>

<style scoped>
.markdownContainer {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
}
</style>
