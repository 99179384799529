<template>
    <div v-if="detailInfo" style="height: 100%">
        <div v-if="analysisMethod === 'Trend'" class="trendOutter">
            <div class="trendContainer">
                <div class="row m-0" style="height: 26rem">
                    <div class="col-4 p-0" style="display: flex; align-items: center;">
                        <span class="badge mb-0" style="font-size: 12px; margin-right: 5px;">{{ "분석 데이터" }}</span>
                        <span class="m-0">{{ `${targetPointName} (${detailInfo.ptIdx ?? "-"})` }}</span>
                    </div>
                    <CommonDoubleLineChartPanel
                        :detailInfo="detailInfo"
                        :resultData="resultData"
                        :chartResize="chartResize"
                        :calendarList="throwCalendarList"
                        :switchValues="switchValues"
                        :showToolbox="true"
                        @brush-end="handleTrendBrushEnd"
                        @brush-clear="calculateTrendTotal"
                        @show-evt-detail="showEventDetailModal"
                        @get-evt-detail="getEnergyCalendar"
                    />
                </div>
                <div class="compareResultTable">
                    <div class="tableHeader">
                        <div class="col-2 p-0">
                            <span class="badge mb-0" style="font-size: 12px; margin-right: 5px;">
                                {{ "비교 데이터" }}
                            </span>
                        </div>
                        <div class="col-10 pr-0" style="display: flex; justify-content: flex-end;">
                            <div class="col-4 input-group mt-2 mb-2 pr-0">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="target-data">{{ "선택 기간" }}</span>
                                </div>
                                <input
                                    type="text"
                                    class="form-control text-center"
                                    :value="
                                        fromChartSelectDate && toChartSelectDate
                                            ? `${fromChartSelectDate} ~ ${toChartSelectDate}`
                                            : '전체'
                                    "
                                    aria-describedby="target-data"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <table class="table table-bordered text-center">
                        <thead>
                            <tr>
                                <th scope="col" class="table-header-style" :style="{ backgroundColor: '#ECEFF1' }">
                                    {{ targetPointName }}
                                </th>
                                <th scope="col" class="table-header-style">{{ compareType }}</th>
                                <th scope="col" class="table-header-style" :style="{ backgroundColor: '#ECEFF1' }">
                                    {{ "증감량" }}
                                </th>
                                <th
                                    scope="col"
                                    style="font-weight: 100 !important;"
                                    :style="{ backgroundColor: '#ECEFF1' }"
                                >
                                    {{ "증감률" }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td
                                    v-for="key in summedKeys"
                                    :key="`trend-${key}`"
                                    :style="{
                                        backgroundColor:
                                            key == 'main' || key == 'incDecAmount' || key == 'incDecPercent'
                                                ? '#ECEFF1'
                                                : '',
                                        height: '50px',
                                    }"
                                >
                                    <span class="m-0 table-value-style">
                                        {{
                                            `${formatNumWithComma(summedData[key])} ${
                                                key !== "incDecPercent" ? summedData["unit"] : "%"
                                            }`
                                        }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <trend-statistic-panel :resultData="resultData" />
                <trend-heat-map-chart-panel-vue :detailInfo="detailInfo" :resultData="resultData" />
            </div>
        </div>
        <div v-else-if="analysisMethod === 'Diff'" style="height: 100%; width: 100%">
            <!-- chart -->
            <div class="row m-0" style="height: 25rem">
                <div class="col-4 p-0" style="display: flex; align-items: center;">
                    <span class="badge mb-0" style="font-size: 12px; margin-right: 5px;">{{ "분석 데이터" }}</span>
                    <span class="m-0">{{ `${targetPointName} (${detailInfo.ptIdx ?? "-"})` }}</span>
                </div>
                <CommonDoubleLineChartPanel
                    :detailInfo="detailInfo"
                    :resultData="resultData"
                    :chartResize="chartResize"
                    :calendarList="throwCalendarList"
                    :switchValues="switchValues"
                    :showToolbox="true"
                    @brush-end="handleDiffBrushEnd"
                    @brush-clear="calculateDiffTotal"
                    @show-evt-detail="showEventDetailModal"
                    @get-evt-detail="getEnergyCalendar"
                />
            </div>
            <div class="row m-0" style="margin: 2rem 0px 0px 0px !important; align-items: center;">
                <div class="col-2 p-0">
                    <span class="badge mb-0" style="font-size: 12px; margin-right: 5px;">{{ "비교 데이터" }}</span>
                </div>
                <div class="col-10 p-0" style="display: flex; justify-content: flex-end;">
                    <div class="col-4 p-0 input-group mr-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="target-data">{{ "선택 기간" }}</span>
                        </div>
                        <input
                            type="text"
                            class="form-control text-center"
                            :value="
                                fromChartSelectDate && toChartSelectDate
                                    ? `${fromChartSelectDate} ~ ${toChartSelectDate}`
                                    : '전체'
                            "
                            aria-describedby="target-data"
                            disabled
                        />
                    </div>
                    <div class="col-2 p-0 input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="target-data">{{ "차트 표시수" }}</span>
                        </div>
                        <select v-model="chartDisp" class="form-control" name="" id="">
                            <option :value="1">{{ "1" }}</option>
                            <option :value="2">{{ "2" }}</option>
                            <option :value="3">{{ "3" }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-lg-12 p-0 mt-2">
                    <table class="table table-bordered text-center">
                        <thead>
                            <tr>
                                <th
                                    v-for="key in summedKeys"
                                    :key="key"
                                    scope="col"
                                    class="table-header-style"
                                    :style="{ backgroundColor: detailInfo.ptIdx == key ? '#ECEFF1' : '' }"
                                >
                                    {{
                                        resultData.headers.find((item) => item.ptIdx == key)
                                            ? resultData.headers.find((item) => item.ptIdx == key).ptName
                                            : key
                                    }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td
                                    v-for="key in summedKeys"
                                    :key="key"
                                    :style="{
                                        backgroundColor: detailInfo.ptIdx == key ? '#ECEFF1' : '',
                                        height: '50px',
                                    }"
                                >
                                    <span class="m-0 table-value-style">
                                        {{ `${formatNumWithComma(summedData[key].value)} ${summedData[key].unit}` }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row m-0" style="height: 300px; margin: 2rem 0px 0px 0px !important">
                <div
                    v-for="(component, index) in diffChartData"
                    :key="component.header.ptIdx"
                    class="p-0"
                    style="height: 100%"
                    :class="chartDisp == 1 ? 'col-12' : chartDisp == 2 ? 'col-6' : 'col-4'"
                >
                    <CompareLineChartPanel
                        :detailInfo="detailInfo"
                        :chartData="component"
                        :chartDisp="chartDisp"
                        :chartResize="chartResize"
                        :chartIndex="index"
                        @hover-event="syncHoverEvent"
                        ref="charts"
                    />
                </div>
            </div>
        </div>
        <div v-else-if="analysisMethod === 'Comp'" style="height: 100%; width: 100%">
            <div class="row m-0" style="height: 25rem">
                <div class="col-4 p-0" style="display: flex; align-items: center;">
                    <span class="badge mb-0" style="font-size: 12px; margin-right: 5px;">{{ "분석 데이터" }}</span>
                    <span class="m-0">{{ `${targetPointName} (${detailInfo.ptIdx ?? "-"})` }}</span>
                </div>
                <CommonDoubleLineChartPanel
                    :detailInfo="detailInfo"
                    :resultData="resultData"
                    :chartResize="chartResize"
                    :calendarList="throwCalendarList"
                    :switchValues="switchValues"
                    :showToolbox="true"
                    @brush-end="handleCompBrushEnd"
                    @brush-clear="calculateCompTotal"
                    @show-evt-detail="showEventDetailModal"
                    @get-evt-detail="getEnergyCalendar"
                />
            </div>
            <!-- 성분 분석 -->
            <!-- table -->
            <div class="compResultTable">
                <div class="tableHeader">
                    <div class="col-2 p-0">
                        <!-- <h5 class="mb-0">{{ "" }}</h5> -->
                        <span class="badge mb-0" style="font-size: 12px; margin-right: 5px;">{{ "구성 데이터" }}</span>
                    </div>
                    <div class="col-10 pr-0" style="display: flex; justify-content: flex-end;">
                        <div class="col-4 input-group mt-2 mb-2 pr-0">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="target-data">{{ "선택 기간" }}</span>
                            </div>
                            <input
                                type="text"
                                class="form-control text-center"
                                :value="
                                    fromChartSelectDate && toChartSelectDate
                                        ? `${fromChartSelectDate} ~ ${toChartSelectDate}`
                                        : '전체'
                                "
                                aria-describedby="target-data"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div class="compTable">
                    <div class="col-lg-12 p-0">
                        <table class="table table-bordered text-center">
                            <thead>
                                <tr>
                                    <th
                                        v-for="key in summedKeys"
                                        :key="`comp-header-${key}`"
                                        scope="col"
                                        class="table-header-style"
                                        :style="{
                                            backgroundColor: detailInfo.ptIdx == key || key == 'total' ? '#ECEFF1' : '',
                                        }"
                                    >
                                        {{
                                            resultData.headers.find((item) => item.ptIdx == key)
                                                ? resultData.headers.find((item) => item.ptIdx == key).ptName
                                                : "합계"
                                        }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td
                                        v-for="key in summedKeys"
                                        :key="`comp-body-${key}`"
                                        :style="{
                                            backgroundColor: detailInfo.ptIdx == key || key == 'total' ? '#ECEFF1' : '',
                                            height: '45px',
                                        }"
                                    >
                                        <span class="m-0 table-value-style">
                                            {{ `${formatNumWithComma(summedData[key].value)} ${summedData[key].unit}` }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        v-for="key in summedKeys"
                                        :key="`comp-body-${key}`"
                                        :style="{
                                            backgroundColor: detailInfo.ptIdx == key || key == 'total' ? '#ECEFF1' : '',
                                            height: '50px',
                                        }"
                                    >
                                        <span class="m-0 table-value-style">
                                            {{ `${summedData[key].percent} %` }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!-- bottom stack and doughnut chart -->
            <div class="row m-0" style="height: 300px;">
                <div style="width:100%;height: 100%">
                    <CompStackChartPanel
                        :detailInfo="detailInfo"
                        :resultData="resultData"
                        :chartResize="chartResize"
                        :unit="unit"
                    />
                </div>
            </div>
        </div>

        <div v-else-if="analysisMethod === 'Reltn'" class="reltnContainer">
            <div class="reltnSummary">
                <div class="reltnTitle">
                    상관 분석 결과
                </div>

                <div class="reltnSummaryTable">
                    <b-table-simple bordered class="text-center" style="marginRight:5px">
                        <b-tbody>
                            <b-tr style="height:10%;">
                                <b-td class="tableStyle">모델식</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td class="formula align-middle">
                                    <katex-markdown :formula="equation"></katex-markdown>
                                    <katex-markdown-r-2 :R2AdjR2="R2AdjR2"></katex-markdown-r-2>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <b-table-simple bordered class="text-center" style="marginRight:5px">
                        <b-tbody>
                            <b-tr>
                                <b-td class="tableStyle align-middle" style="width: 20%">R2</b-td>
                                <b-th class="align-middle" style="width: 30% ">{{
                                    resultData?.regressionEvaluation?.R2 ?? "-"
                                }}</b-th>
                                <b-td class="tableStyle align-middle" style="width: 20%">보정 R2</b-td>
                                <b-th class="align-middle" style="width: 30%" colspan="2">{{
                                    resultData?.regressionEvaluation?.adjustedR2 ?? "-"
                                }}</b-th>
                            </b-tr>

                            <b-tr>
                                <b-td class="tableStyle align-middle">
                                    종속변수(Y)
                                </b-td>
                                <b-th class="align-middle" colspan="4">{{ targetPointName }} </b-th>
                            </b-tr>
                            <b-tr>
                                <b-td class="tableStyle align-middle">독립변수(X)</b-td>
                                <b-th class="align-middle">데이터</b-th>
                                <b-th class="align-middle">Coefficient</b-th>
                                <b-th class="align-middle">P-value</b-th>
                                <b-th class="align-middle">
                                    VIF
                                </b-th>
                            </b-tr>
                            <b-tr v-for="(item, i) in factorsInfo" :key="i">
                                <b-td class="tableStyle align-middle">X{{ i + 1 }}</b-td>
                                <b-th class="align-middle">{{ item.ptName }}</b-th>
                                <b-th class="align-middle" v-if="resultData && resultData.regressionEvaluation">
                                    {{ resultData.regressionEvaluation.coefficients[i + 1] }}
                                </b-th>
                                <b-th class="align-middle" v-if="resultData && resultData.regressionEvaluation">
                                    {{ resultData.regressionEvaluation.pValues[i + 1] }}
                                </b-th>
                                <b-th class="align-middle" v-if="resultData && resultData.regressionEvaluation">
                                    {{ resultData.regressionEvaluation.vif[i] }}
                                </b-th>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <b-table-simple bordered>
                        <!-- <b-tbody>
                            <b-tr v-for="(item, i) in evalResult" :key="i">
                                {{ item }}
                            </b-tr>
                        </b-tbody> -->
                        <div class="markDownReport">
                            <markdown-it-vue :content="evalResult"></markdown-it-vue>
                        </div>
                    </b-table-simple>
                </div>
            </div>
            <div v-for="(item, i) in correlationData" :key="i" class="reltnBox">
                <div class="reltnTitle">vs {{ item.ptName }}</div>
                <div class="reltnBody d-flex">
                    <div class="chart-container" style="flex: 40%; height: 100%">
                        <div class="reltnChart">
                            <CommonDoubleLineChartPanel
                                :detailInfo="detailInfo"
                                :resultData="resultData"
                                :chartResize="chartResize"
                                narrow="12%"
                                :calendarList="throwCalendarList"
                                :showToolbox="false"
                            />
                        </div>
                    </div>
                    <div class="chart-container" style="flex: 40%; height: 100%">
                        <div class="reltnChart">
                            <ReltnScatterChartPanel
                                :detailInfo="detailInfo"
                                :resultData="resultData"
                                :chartResize="chartResize"
                                :corrData="item"
                                :unit="unit"
                            />
                        </div>
                    </div>

                    <div
                        class="table-container pt-5"
                        style="flex: 20%; height: 100%; display: flex; flex-direction: column;"
                    >
                        <div class="statisticButtonContainer">
                            <button
                                class="col status-icon mr-1"
                                :class="{ active: activeIcon === 'statisticReport' }"
                                @click="handleIconClick('statisticReport')"
                                :title="'통계지표'"
                            >
                                <span>분석지표</span>
                            </button>
                            <button
                                class="col status-icon"
                                :class="{ active: activeIcon === 'statisticResult' }"
                                @click="handleIconClick('statisticResult')"
                                :title="'통계지표'"
                            >
                                <span>분석결과</span>
                            </button>
                        </div>
                        <div class="equationContainer">Y = {{ item.variableEvaluation.equation }}</div>
                        <div
                            v-if="activeIcon === 'statisticReport'"
                            class="table-container"
                            style="flex: 1; overflow-y: auto;"
                        >
                            <b-table-simple bordered class="text-center">
                                <b-thead>
                                    <b-tr>
                                        <b-th>{{ "지표" }}</b-th>
                                        <b-th colspan="2">{{ "지표값" }}</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr>
                                        <b-th :rowspan="item.variableEvaluation.coefficients.length + 1">회귀계수</b-th>
                                        <b-th>Y절편</b-th>
                                        <b-td>{{
                                            item.variableEvaluation.coefficients[0]
                                                ? item.variableEvaluation.coefficients[0]
                                                : 0
                                        }}</b-td>
                                    </b-tr>
                                    <b-tr
                                        v-for="(cfc, cfcIndex) in item.variableEvaluation.coefficients"
                                        :key="cfcIndex + 10"
                                    >
                                        <b-th v-if="cfcIndex !== 0">{{ `X${cfcIndex}계수` }}</b-th>
                                        <b-td v-if="cfcIndex !== 0">{{ cfc ? cfc : 0 }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ "R2" }}</b-th>
                                        <b-td colspan="2">{{
                                            item.variableEvaluation.R2 ? item.variableEvaluation.R2 : 0
                                        }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ "보정 R2" }}</b-th>
                                        <b-td colspan="2">{{
                                            item.variableEvaluation.adjustedR2 ? item.variableEvaluation.adjustedR2 : 0
                                        }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th :rowspan="item.variableEvaluation.pValues.length + 1">P-Value</b-th>
                                        <b-th>Y절편</b-th>
                                        <b-td>{{
                                            item.variableEvaluation.pValues[0] ? item.variableEvaluation.pValues[0] : 0
                                        }}</b-td>
                                    </b-tr>
                                    <b-tr
                                        v-for="(pVal, pValIndex) in item.variableEvaluation.pValues"
                                        :key="pValIndex + 20"
                                    >
                                        <b-th v-if="pValIndex !== 0">{{ `X${pValIndex}계수` }}</b-th>
                                        <b-td v-if="pValIndex !== 0">{{ pVal ? pVal : 0 }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th :rowspan="item.variableEvaluation.vif.length + 1">다중공선성</b-th>
                                        <b-th>X1</b-th>
                                        <b-th>{{
                                            item.variableEvaluation.vif[0] ? item.variableEvaluation.vif[0] : 0
                                        }}</b-th>
                                    </b-tr>
                                    <b-tr v-for="(vif, vifIndex) in item.variableEvaluation.vif" :key="vifIndex + 30">
                                        <b-th v-if="vifIndex !== 0">{{ `X${vifIndex + 1}` }}</b-th>
                                        <b-td v-if="vifIndex !== 0">{{ vif ? vif : 0 }}</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                        <!-- 분석결과 -->
                        <div v-if="activeIcon === 'statisticResult'" class="msgBox">
                            <div class="markDownReport">
                                <markdown-it-vue :content="evalResult"></markdown-it-vue>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="analysisMethod === 'Goal'" style="height: 100%; width: 100%">
            <div class="row m-0">
                <!-- waterfall chart -->
                <div class="col-12 p-0" style="height: 300px">
                    <!-- {{ "waterfall chart" }} -->
                    <GoalWaterFallChartPanel
                        :detailInfo="detailInfo"
                        :resultData="resultData"
                        :chartResize="chartResize"
                    />
                </div>
                <!-- table -->
                <div class="col-12 p-0">
                    <GoalResultTable :detailInfo="detailInfo" :resultData="resultData" />
                </div>
            </div>
        </div>
        <div v-else-if="analysisMethod === 'Cost'" style="height: 100%; width: 100%">
            {{ "비용 분석 결과" }}
        </div>
        <h4 v-else>{{ "no match analysis method" }}</h4>

        <b-modal ref="eventDetailModal" centered>
            <template #modal-header>
                <h5 class="mb-0">{{ "이벤트 상세" }}</h5>
            </template>
            <EventDetailModalPanel :eventDetail="eventDetail" />
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button
                        class="btn btn-sm btn-secondary"
                        style="flex: 1;"
                        @click="() => $refs.eventDetailModal.hide()"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import CommonDoubleLineChartPanel from "../charts/CommonDoubleLineChartPanel.vue";
import CompareLineChartPanel from "../charts/CompareLineChartPanel.vue";
//트렌드분석
import TrendStatisticPanel from "../charts/TrendStatisticPanel.vue";
import TrendHeatMapChartPanelVue from "../charts/TrendHeatMapChartPanel.vue";
// 성분 분석
import CompStackChartPanel from "../charts/CompStackChartPanel.vue";

// 상관분석
import ReltnScatterChartPanel from "../charts/ReltnScatterChartPanel.vue";
// 목표분석
import GoalWaterFallChartPanel from "../charts/GoalWaterFallChartPanel.vue";
import GoalResultTable from "../tables/GoalResultTable.vue";

//마크다운
import KatexMarkdown from "@src/views/component/markdownComponent/KatexMarkdown.vue";
import KatexMarkdownR2 from "@src/views/component/markdownComponent/KatexMarkdownR2.vue";
import MarkdownItVue from "markdown-it-vue";
import "markdown-it-vue/dist/markdown-it-vue.css";
// import MarkdownItVue from "markdown-it-vue";
// import "markdown-it-vue/dist/markdown-it-vue.css";

import EventDetailModalPanel from "./EventDetailModalPanel.vue";

export default {
    props: ["detailInfo", "chartResize", "analysisMethod", "resultData", "calendarList", "switchValues"],
    components: {
        KatexMarkdown,
        KatexMarkdownR2,
        MarkdownItVue,
        TrendHeatMapChartPanelVue,
        // TrendLineChartPanel,
        TrendStatisticPanel,

        CommonDoubleLineChartPanel,
        CompareLineChartPanel,

        CompStackChartPanel,

        ReltnScatterChartPanel,

        GoalWaterFallChartPanel,
        GoalResultTable,

        EventDetailModalPanel,
    },
    data() {
        return {
            // unit: this.resultData.headers[0].unitSmallCode,

            activeIcon: "statisticReport",
            chartDisp: 1,
            currentMonth: new Date().getMonth(),
            highlightingKeyword: ["Adjusted R", "회귀 계수", "p-value", "VIF"],
            yearTableData: null,

            // chart brush data fetching property
            brushData: null,
            fromChartSelectDate: null,
            toChartSelectDate: null,
            summedData: null,
            summedKeys: null,
            compPercent: null,

            eventDetail: null,
        };
    },
    computed: {
        R2AdjR2() {
            if (this.resultData.regressionEvaluation) {
                return [this.resultData.regressionEvaluation.R2, this.resultData.regressionEvaluation.adjustedR2];
            } else {
                return "데이터 없음";
            }
        },
        evalResult() {
            return this.resultData?.regressionEvaluation?.evalResult || "데이터 없음";
        },
        equation() {
            if (this.resultData?.regressionEvaluation?.equation) {
                return this.resultData.regressionEvaluation.equation;
            } else {
                return `데이터 없음`;
            }
        },
        factorsInfo() {
            if (!this.resultData || !this.resultData.variableEvaluation) return;
            const keys = Object.keys(this.resultData.variableEvaluation);
            console.log("factorsInfo keys:", keys);

            const factorArr = this.resultData.headers.filter((item) => keys.includes(String(item.ptIdx)));
            return factorArr;
        },

        targetPointName() {
            const name = this.$store.state.pointList.find((pt) => pt.ptIdx == this.detailInfo.ptIdx);
            return name ? name.ptName : "-";
        },
        compareType() {
            const compareType = this.$store.state.commonCodes.compareType.find(
                (code) => code.value == this.detailInfo.compareType
            );
            return compareType ? compareType.text : "비교 없음";
        },
        timeDsvn() {
            const dsvn = this.$store.state.commonCodes.timeDsvn.find((code) => code.value == this.detailInfo.timeDsvn);
            return dsvn ? dsvn.text : "";
        },
        unit() {
            return this.detailInfo.dispUnit || this.resultData.headers[0].unitSmallCode;
        },
        // <비교분석> 성분 구성 차트 데이터
        diffChartData() {
            let data = [];
            const filterMainHeader = this.resultData.headers.find((item) => item.roleType == "Main");
            if (!this.isEmpty(filterMainHeader)) {
                data.push({
                    header: filterMainHeader ?? {},
                    value: this.resultData.values[this.detailInfo.ptIdx] ?? [],
                    regDt: this.resultData.values.regDt ?? [],
                });
            }
            const filterMemberPt = this.detailInfo.variables.filter((item) => item.roleType == "Compare");
            if (!this.isEmpty(filterMemberPt)) {
                const filterHeader = this.resultData.headers.filter((header) =>
                    filterMemberPt.some((mem) => header.ptIdx == mem.ptIdx)
                );
                filterHeader.forEach((filHeader) => {
                    data.push({
                        header: filHeader ?? {},
                        value: this.resultData.values[filHeader.ptIdx] ?? [],
                        regDt: this.resultData.values.regDt ?? [],
                    });
                });
            }
            return data;
        },
        //성분분석에서 필요한 데이터 유형들입니다.

        //성분 분석 에너지수급량입니다.
        sumBaseComponentEnergy() {
            const relevantHeaders = [
                this.resultData.headers.find((item) => item.ptIdx === String(this.detailInfo.ptIdx)),
                // ...this.resultData.headers.filter((item) => item.role === "Member"),
            ].filter(Boolean);

            const sumVal = relevantHeaders.reduce((acc, item) => {
                const ptIdx = item.ptIdx;
                if (this.resultData.values[ptIdx]) {
                    const arraySum = this.resultData.values[ptIdx].reduce((sum, value) => sum + value, 0);
                    return acc + arraySum;
                }
                return acc;
            }, 0);

            return sumVal.toFixed(2);
        },
        //성분분석에서 "합계" col 값입니다.
        sumCompComponentEnergy() {
            const result = new Map();

            // 관련 헤더 필터링
            const relevantHeaders = [
                ...this.resultData.headers.filter((item) => item.roleType === "Componenet"),
            ].filter(Boolean);

            // 헤더에 해당하는 데이터를 합산하여 Map에 저장
            relevantHeaders.forEach((item) => {
                const ptIdx = item.ptIdx;
                const sum = this.resultData.values[ptIdx]?.reduce((acc, value) => acc + value, 0) || 0;
                result.set(ptIdx, sum);
            });

            // Map 순회하며 모든 값을 더하기
            const totalSum = Array.from(result.values()).reduce((acc, value) => acc + value, 0);

            return totalSum; // 최종 합계 반환
        },
        //성분 분석에서 테이블 데이터 생성함수입니다.
        sumComponentEnergyEach() {
            const result = new Map();

            const relevantHeaders = [
                // this.resultData.headers.find((item) => item.ptIdx === String(this.detailInfo.ptIdx)),
                ...this.resultData.headers.filter((item) => item.roleType === "Component"),
            ].filter(Boolean);

            relevantHeaders.forEach((item) => {
                const ptIdx = item.ptIdx;
                const sum = this.resultData.values[ptIdx]?.reduce((acc, value) => acc + value, 0) || 0;
                result.set(ptIdx, sum);
            });

            const matchResult = relevantHeaders.map((item) => ({
                ptName: item.ptName,
                value: result.get(item.ptIdx) || 0,
            }));

            return matchResult;
        },

        //  상관 분석 데이터셋입니다.
        correlationData() {
            if (!this.resultData.variableEvaluation) return;
            let corrArr = [];
            const baseItem = this.resultData?.headers.find((item) => item.roleType === "Main");
            if (!baseItem) {
                console.error("Base item with role 'Main' not found.");
                return [];
            }

            const resultDataForCorr = this.resultData.headers.filter((item) => item.roleType === "Influence");

            for (const item of resultDataForCorr) {
                const innerCorr = [];
                const baseValues = this.resultData.values[baseItem.ptIdx];
                const itemValues = this.resultData.values[item.ptIdx];

                if (!baseValues || !itemValues) {
                    console.error(`Values not found for ptIdx: ${item.ptIdx}`);
                    continue;
                }

                const length = Math.min(baseValues.length, itemValues.length);
                for (let inner = 0; inner < length; inner++) {
                    innerCorr.push([itemValues[inner], baseValues[inner]]);
                }

                const additionalData = {
                    ptName: item.ptName,
                    // role: item.role,
                    ptIdx: item.ptIdx,
                    unit: item.unitSmallCode,
                    variableEvaluation: this.resultData?.variableEvaluation[item.ptIdx],
                    correlation: innerCorr,
                };

                corrArr.push(additionalData);
            }

            return corrArr;
        },
        throwCalendarList() {
            return this.calendarList || [];
        },
    },
    watch: {
        resultData: {
            immediate: true,
            async handler(newVal) {
                if (newVal) {
                    console.log("resultData watch");
                    await this.processData(newVal);
                    if (this.detailInfo.analysisMethod == "Trend") {
                        await this.calculateTrendTotal();
                    }
                    if (this.detailInfo.analysisMethod == "Diff") {
                        await this.calculateDiffTotal();
                    }
                    if (this.detailInfo.analysisMethod == "Comp") {
                        await this.calculateCompTotal();
                    }
                }
            },
        },
    },
    created() {},
    mounted() {},
    methods: {
        syncHoverEvent({ index, event }) {
            // index와 event 객체를 처리
            this.hoverEvent = event;
            this.$refs.charts.forEach((chart, i) => {
                if (i !== index) {
                    chart.applyHoverEvent(event); // 다른 차트에도 이벤트 전달
                }
            });
        },
        formattingNumber(val) {
            return Number(val).toLocaleString();
        },
        handleIconClick(icon) {
            this.activeIcon = icon;
        },
        highlightedText(text) {
            const keywordPattern = this.highlightingKeyword.join("|");
            const regex = new RegExp(`(${keywordPattern})`, "gi");
            return text.replace(regex, '<span class="highlightMsg">$1</span>');
        },
        processData(data) {
            // Step 1: Identify `Reltn` roles
            const reltnKeys = data.headers
                .filter((header) => header.roleType === "Influence")
                .map((header) => header.ptIdx);

            // Step 2: Filter `values` keys
            const filteredValues = Object.keys(data.values)
                .filter((key) => !reltnKeys.includes(key))
                .reduce((acc, key) => {
                    acc[key] = data.values[key];
                    return acc;
                }, {});
            this.brushData = filteredValues;
        },
        async handleTrendBrushEnd(event) {
            const brushed = event.areas[0];
            this.fromChartSelectDate = this.resultData.values.regDt[brushed.coordRange[0]];
            this.toChartSelectDate = this.resultData.values.regDt[brushed.coordRange[1]];

            const { coordRange } = brushed;
            const startIdx = Math.round(coordRange[0]);
            const endIdx = Math.round(coordRange[1]);
            const mainData = this.brushData[this.detailInfo.ptIdx].slice(startIdx, endIdx + 1);
            const compareData = this.resultData.comparison
                ? this.resultData.comparison[this.detailInfo.ptIdx].value.slice(startIdx, endIdx + 1)
                : [];
            const findHeader = this.resultData.headers.find((item) => item.ptIdx == this.detailInfo.ptIdx);

            let ptTypeCode;
            let findUnit;
            let unit;
            if (!this.isEmpty(findHeader)) {
                ptTypeCode = findHeader.ptTypeCode ?? "SUM";
                findUnit = this.$store.state.units.find((item) => item.value == findHeader.unitSmallCode);
                unit = findHeader.dispUnit ? findHeader.dispUnit : findUnit ? findUnit.text : "-";
            }

            const summaryData = {
                main: null,
                compare: null,
                incDecAmount: null,
                incDecPercent: null,
                unit: unit,
            };

            if (!this.isEmpty(mainData)) {
                if (ptTypeCode == "SUM") {
                    summaryData.main = mainData.reduce((sum, value) => sum + (value || 0), 0).toFixed(1);
                    summaryData.compare = !this.isEmpty(compareData)
                        ? compareData.reduce((sum, value) => sum + (value || 0), 0).toFixed(1)
                        : 0;
                    summaryData.incDecAmount = (summaryData.main - summaryData.compare).toFixed(1);
                    summaryData.incDecPercent = (
                        ((summaryData.compare - summaryData.main) / summaryData.main) *
                        100
                    ).toFixed(1);
                } else if (ptTypeCode == "AVG") {
                    summaryData.main = mainData.reduce((sum, value) => sum + (value || 0), 0) / mainData.length;
                    summaryData.compare = !this.isEmpty(compareData)
                        ? compareData.reduce((sum, value) => sum + (value || 0), 0) / compareData.length
                        : 0;
                    summaryData.incDecAmount = (summaryData.main - summaryData.compare).toFixed(1);
                    summaryData.incDecPercent = (
                        ((summaryData.compare - summaryData.main) / summaryData.main) *
                        100
                    ).toFixed(1);
                } else if (ptTypeCode == "LST") {
                    summaryData.main = summaryData.mainData[summaryData.mainData.length - 1];
                    summaryData.compare = !this.isEmpty(compareData)
                        ? summaryData.compareData[summaryData.compareData.length - 1]
                        : 0;
                    summaryData.incDecAmount = (summaryData.main - summaryData.compare).toFixed(1);
                    summaryData.incDecPercent = (
                        ((summaryData.compare - summaryData.main) / summaryData.main) *
                        100
                    ).toFixed(1);
                } else if (ptTypeCode == "MAX") {
                    summaryData.main = Math.max(...mainData);
                    summaryData.compare = !this.isEmpty(compareData) ? Math.max(...compareData) : 0;
                    summaryData.incDecAmount = (summaryData.main - summaryData.compare).toFixed(1);
                    summaryData.incDecPercent = (
                        ((summaryData.compare - summaryData.main) / summaryData.main) *
                        100
                    ).toFixed(1);
                } else return;
            }
            this.summedData = summaryData;
        },
        async calculateTrendTotal() {
            this.fromChartSelectDate = null;
            this.toChartSelectDate = null;
            const mainData = this.brushData[this.detailInfo.ptIdx];
            const compareData = !this.isEmpty(this.resultData.comparison)
                ? this.resultData.comparison[this.detailInfo.ptIdx].value
                : [];
            const findHeader = this.resultData.headers.find((item) => item.ptIdx == this.detailInfo.ptIdx);
            let ptTypeCode;
            let findUnit;
            let unit;
            if (!this.isEmpty(findHeader)) {
                ptTypeCode = findHeader.ptTypeCode ?? "SUM";
                findUnit = this.$store.state.units.find((item) => item.value == findHeader.unitSmallCode);
                unit = findHeader.dispUnit ? findHeader.dispUnit : findUnit ? findUnit.text : "-";
            }
            let total = {
                main: null,
                compare: null,
                incDecAmount: null,
                incDecPercent: null,
                unit: unit,
            };
            if (!this.isEmpty(mainData)) {
                if (ptTypeCode == "SUM") {
                    total.main = mainData.reduce((sum, value) => sum + (value || 0), 0).toFixed(1);
                    total.compare = !this.isEmpty(compareData)
                        ? compareData.reduce((sum, value) => sum + (value || 0), 0).toFixed(1)
                        : 0;
                    total.incDecAmount = (total.main - total.compare).toFixed(1);
                    total.incDecPercent = (((total.compare - total.main) / total.main) * 100).toFixed(1);
                } else if (ptTypeCode == "AVG") {
                    total.main = mainData.reduce((sum, value) => sum + (value || 0), 0) / mainData.length;
                    total.compare = !this.isEmpty(compareData)
                        ? compareData.reduce((sum, value) => sum + (value || 0), 0) / compareData.length
                        : 0;
                    total.incDecAmount = (total.main - total.compare).toFixed(1);
                    total.incDecPercent = (((total.compare - total.main) / total.main) * 100).toFixed(1);
                } else if (ptTypeCode == "LST") {
                    total.main = total.mainData[total.mainData.length - 1];
                    total.compare = !this.isEmpty(compareData) ? total.compareData[total.compareData.length - 1] : 0;
                    total.incDecAmount = (total.main - total.compare).toFixed(1);
                    total.incDecPercent = (((total.compare - total.main) / total.main) * 100).toFixed(1);
                } else if (ptTypeCode == "MAX") {
                    total.main = Math.max(...mainData);
                    total.compare = !this.isEmpty(compareData) ? Math.max(...compareData) : 0;
                    total.incDecAmount = (total.main - total.compare).toFixed(1);
                    total.incDecPercent = (((total.compare - total.main) / total.main) * 100).toFixed(1);
                } else return;
            }
            this.summedData = total;
            this.summedKeys = Object.keys(this.summedData).filter((fil) => fil !== "unit");
        },
        async handleDiffBrushEnd(event) {
            const brushed = event.areas[0];
            this.fromChartSelectDate = this.resultData.values.regDt[brushed.coordRange[0]];
            this.toChartSelectDate = this.resultData.values.regDt[brushed.coordRange[1]];

            const { coordRange } = brushed;
            const startIdx = Math.round(coordRange[0]);
            const endIdx = Math.round(coordRange[1]);
            const summaryData = {};

            Object.keys(this.brushData).forEach((key) => {
                if (key !== "regDt") {
                    const findHeader = this.resultData.headers.find((item) => item.ptIdx == key);
                    let ptTypeCode;
                    let findUnit;
                    let unit;
                    if (!this.isEmpty(findHeader)) {
                        ptTypeCode = findHeader.ptTypeCode ?? "SUM";
                        findUnit = this.$store.state.units.find((item) => item.value == findHeader.unitSmallCode);
                        unit = findHeader.dispUnit ? findHeader.dispUnit : findUnit ? findUnit.text : "-";
                    }
                    const selectedValues = this.brushData[key].slice(startIdx, endIdx + 1);
                    summaryData[key] = {};
                    if (ptTypeCode == "SUM") {
                        summaryData[key].value = selectedValues.reduce((sum, value) => sum + (value || 0), 0);
                        summaryData[key].unit = unit;
                    } else if (ptTypeCode == "AVG") {
                        summaryData[key].value =
                            selectedValues.reduce((sum, value) => sum + (value || 0), 0) / selectedValues.length;
                        summaryData[key].unit = unit;
                    } else if (ptTypeCode == "LST") {
                        summaryData[key].value = selectedValues[selectedValues.length - 1];
                        summaryData[key].unit = unit;
                    } else if (ptTypeCode == "MAX") {
                        summaryData[key].value = Math.max(...selectedValues);
                        summaryData[key].unit = unit;
                    } else return;
                }
            });

            this.summedData = summaryData;
        },
        async calculateDiffTotal() {
            this.fromChartSelectDate = null;
            this.toChartSelectDate = null;
            const totalSum = {};
            Object.keys(this.brushData).forEach((key) => {
                if (key !== "regDt") {
                    totalSum[key] = this.brushData[key].reduce((sum, value) => sum + (value || 0), 0);

                    const findHeader = this.resultData.headers.find((item) => item.ptIdx == key);
                    let ptTypeCode;
                    let findUnit;
                    let unit;
                    if (!this.isEmpty(findHeader)) {
                        ptTypeCode = findHeader.ptTypeCode ?? "SUM";
                        findUnit = this.$store.state.units.find((item) => item.value == findHeader.unitSmallCode);
                        unit = findHeader.dispUnit ? findHeader.dispUnit : findUnit ? findUnit.text : "-";
                    }
                    totalSum[key] = {};
                    if (ptTypeCode == "SUM") {
                        totalSum[key].value = this.brushData[key].reduce((sum, value) => sum + (value || 0), 0);
                        totalSum[key].unit = unit;
                    } else if (ptTypeCode == "AVG") {
                        totalSum[key].value =
                            this.brushData[key].reduce((sum, value) => sum + (value || 0), 0) /
                            this.brushData[key].length;
                        totalSum[key].unit = unit;
                    } else if (ptTypeCode == "LST") {
                        totalSum[key].value = this.brushData[key][this.brushData[key].length - 1];
                        totalSum[key].unit = unit;
                    } else if (ptTypeCode == "MAX") {
                        totalSum[key].value = Math.max(...this.brushData[key]);
                        totalSum[key].unit = unit;
                    } else return;
                }
            });
            this.summedData = totalSum;
            this.summedKeys = Object.keys(this.summedData);
        },

        async handleCompBrushEnd(event) {
            const brushed = event.areas[0];
            this.fromChartSelectDate = this.resultData.values.regDt[brushed.coordRange[0]];
            this.toChartSelectDate = this.resultData.values.regDt[brushed.coordRange[1]];

            const header = this.resultData.headers.find((header) => header.ptIdx == this.detailInfo.ptIdx);
            let findUnit;
            let unit;
            if (!this.isEmpty(header)) {
                findUnit = this.$store.state.units.find((unit) => unit.value == header.unitSmallCode);
                unit = header.dispUnit ? header.dispUnit : findUnit ? findUnit.text : "-";
            }

            const { coordRange } = brushed;
            const startIdx = Math.round(coordRange[0]);
            const endIdx = Math.round(coordRange[1]);
            const summaryData = {};

            Object.keys(this.brushData).forEach((key) => {
                if (key !== "regDt") {
                    const findHeader = this.resultData.headers.find((item) => item.ptIdx == key);
                    let ptTypeCode;
                    let findUnit;
                    let unit;
                    if (!this.isEmpty(findHeader)) {
                        ptTypeCode = findHeader.ptTypeCode ?? "SUM";
                        findUnit = this.$store.state.units.find((item) => item.value == findHeader.unitSmallCode);
                        unit = findHeader.dispUnit ? findHeader.dispUnit : findUnit ? findUnit.text : "-";
                    }
                    const selectedValues = this.brushData[key].slice(startIdx, endIdx + 1);
                    summaryData[key] = {};
                    if (ptTypeCode == "SUM") {
                        summaryData[key].value = selectedValues.reduce((sum, value) => sum + (value || 0), 0);
                        summaryData[key].unit = unit;
                    } else if (ptTypeCode == "AVG") {
                        summaryData[key].value =
                            selectedValues.reduce((sum, value) => sum + (value || 0), 0) / selectedValues.length;
                        summaryData[key].unit = unit;
                    } else if (ptTypeCode == "LST") {
                        summaryData[key].value = selectedValues[selectedValues.length - 1];
                        summaryData[key].unit = unit;
                    } else if (ptTypeCode == "MAX") {
                        summaryData[key].value = Math.max(...selectedValues);
                        summaryData[key].unit = unit;
                    } else return;

                    if (this.detailInfo.ptIdx == key) summaryData[key].type = "main";
                    else summaryData[key].type = "comp";
                }
            });
            summaryData.total = {
                value: Object.keys(summaryData)
                    .filter((key) => summaryData[key].type !== "main" && summaryData[key].value !== undefined)
                    .reduce((sum, key) => sum + (summaryData[key].value || 0), 0),
                unit: unit,
                type: "total",
            };

            const mainValue = Object.values(summaryData).find((item) => item.type === "main")?.value || 0;
            const totalValue = summaryData.total.value || 0;

            Object.keys(summaryData).forEach((key) => {
                if (summaryData[key].type === "main") {
                    summaryData[key].percent = 100;
                } else if (summaryData[key].type === "total") {
                    summaryData[key].percent = mainValue ? ((totalValue / mainValue) * 100).toFixed(2) : 0;
                } else {
                    summaryData[key].percent = totalValue
                        ? ((summaryData[key].value / totalValue) * 100).toFixed(2)
                        : 0;
                }
            });

            this.summedData = summaryData;
        },
        async calculateCompTotal() {
            this.fromChartSelectDate = null;
            this.toChartSelectDate = null;
            const header = this.resultData.headers.find((header) => header.ptIdx == this.detailInfo.ptIdx);
            let findUnit;
            let unit;
            if (!this.isEmpty(header)) {
                findUnit = this.$store.state.units.find((unit) => unit.value == header.unitSmallCode);
                unit = header.dispUnit ? header.dispUnit : findUnit ? findUnit.text : "-";
            }
            const totalSum = {};
            // const percent = {};
            Object.keys(this.brushData).forEach((key) => {
                if (key !== "regDt") {
                    totalSum[key] = this.brushData[key].reduce((sum, value) => sum + (value || 0), 0);

                    const findHeader = this.resultData.headers.find((item) => item.ptIdx == key);
                    let ptTypeCode;
                    let findUnit;
                    let unit;
                    if (!this.isEmpty(findHeader)) {
                        ptTypeCode = findHeader.ptTypeCode ?? "SUM";
                        findUnit = this.$store.state.units.find((item) => item.value == findHeader.unitSmallCode);
                        unit = findHeader.dispUnit ? findHeader.dispUnit : findUnit ? findUnit.text : "-";
                    }
                    totalSum[key] = {};
                    if (ptTypeCode == "SUM") {
                        totalSum[key].value = this.brushData[key].reduce((sum, value) => sum + (value || 0), 0);
                        totalSum[key].unit = unit;
                    } else if (ptTypeCode == "AVG") {
                        totalSum[key].value =
                            this.brushData[key].reduce((sum, value) => sum + (value || 0), 0) /
                            this.brushData[key].length;
                        totalSum[key].unit = unit;
                    } else if (ptTypeCode == "LST") {
                        totalSum[key].value = this.brushData[key][this.brushData[key].length - 1];
                        totalSum[key].unit = unit;
                    } else if (ptTypeCode == "MAX") {
                        totalSum[key].value = Math.max(...this.brushData[key]);
                        totalSum[key].unit = unit;
                    } else return;

                    if (this.detailInfo.ptIdx == key) totalSum[key].type = "main";
                    else totalSum[key].type = "comp";
                }
            });
            totalSum.total = {
                value: Object.keys(totalSum)
                    .filter((key) => totalSum[key].type !== "main" && totalSum[key].value !== undefined)
                    .reduce((sum, key) => sum + (totalSum[key].value || 0), 0),
                unit: unit,
                type: "total",
            };

            const mainValue = Object.values(totalSum).find((item) => item.type === "main")?.value || 0;
            const totalValue = totalSum.total.value || 0;

            Object.keys(totalSum).forEach((key) => {
                if (totalSum[key].type === "main") {
                    totalSum[key].percent = 100;
                } else if (totalSum[key].type === "total") {
                    totalSum[key].percent = mainValue ? ((totalValue / mainValue) * 100).toFixed(2) : 0;
                } else {
                    totalSum[key].percent = totalValue ? ((totalSum[key].value / totalValue) * 100).toFixed(2) : 0;
                }
            });

            this.summedData = totalSum;
            this.summedKeys = Object.keys(this.summedData);
        },

        formatNumWithComma(value) {
            let formatValue;

            if (!isNaN(value)) {
                formatValue =
                    typeof value == "number"
                        ? Number(value.toFixed(1))._comma()
                        : Number(Number(value).toFixed(1))._comma();
            } else {
                formatValue = value;
            }
            return formatValue;
        },
        showEventDetailModal() {
            this.$refs.eventDetailModal.show();
        },
        async getEnergyCalendar(id) {
            try {
                let result = await backEndApi.analysisModel.getEnergyCalendar(id);
                if (result.status == 200) this.eventDetail = result.data;
                else this.alertNoti(`${result.data.message}`);
            } catch (e) {
                console.error(e);
            }
        },
    },
};
</script>

<style scoped>
.markDownReport {
    padding: 10px 15px;
}
.tableHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.formula {
    font-size: 15px;
    font-weight: bold;
}
.reltnSummary {
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    padding: 5px 15px;
    border-radius: 15px;
}
.reltnSummaryTable {
    display: flex;
}
.tableStyle {
    background-color: #eeeeee;
}
.customTableDanger {
    background-color: #ffe4e6;
}
.customTableWarning {
    background-color: #fffacd;
}
.customTableInfo {
    background-color: #e0ffff;
}
.reltnTitle {
    font-weight: bold;
    padding: 15px 10px;
}
.reltnBody {
    width: 100%;
    height: 100%;
}
.reltnContainer {
    height: 100%;
    width: 100%;
    display: flex; /* Flex 컨테이너로 설정 */
    flex-direction: column; /* 수직 정렬 */
    gap: 10px; /* 박스 간격 */
}
.reltnBox {
    flex: 1; /* 가용 공간을 균등하게 차지 */
    width: 100%;
    margin: 7px 0;
    padding: 0px 10px 15px 10px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    border-radius: 15px;
    display: flex; /* 내부 콘텐츠 정렬용 */
    flex-direction: column; /* 수직 콘텐츠 정렬 */
}
.reltnChart {
    width: 100%;
    height: 100%;
}

.compareResultTable .table {
    margin-bottom: 0;
}
.trendOutter {
    height: 100%;
}
.trendContainer {
    width: 100%;
    height: 100%;
}
.compareResultTable {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 2rem;
}
.compResultTable {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 2rem;
}
.compTable {
    display: flex;
}
.equationContainer {
    display: flex;
    justify-content: center;
    background-color: #555;
    color: yellow;
    border-radius: 5px;
    line-height: 35px;
    font-weight: 600;
}
.statisticButtonContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.table-container {
    width: 100%;
    overflow-y: auto;
    margin-top: 5px;
}
.status-icon {
    color: #555;
    background-color: #fff;
    border: solid #bbb 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.status-icon:hover {
    background-color: #c7c7c7;
}

.status-icon.active {
    color: #fff;
    background-color: #555;
}
.status-icon:focus,
.status-icon:active {
    outline: none;
    box-shadow: none;
}
.chart-container {
    /* border-right: 1px solid #ececec; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.msgBox {
    margin-top: 4px;
    padding: 4px;
    border: solid #bbb 1px;
    border-radius: 5px;
    max-height: 302px;
    overflow-y: auto;
}
.table-header-style {
    font-weight: 100 !important;
}

.table-value-style {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: bold;
    font-size: 13px;
}
</style>
